<!-- 添加渠道客户 -->
<template>
  <el-dialog
      width="40%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'编辑司机':'添加司机'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="130px">
      <el-form-item label="姓名:" prop="realname">
        <el-input
            clearable
            v-model="form.realname"
            placeholder="请输入姓名"/>
      </el-form-item>
      <el-form-item label="手机号:" prop="phone">
        <el-input
            clearable
            v-model="form.phone"
            placeholder="请输入手机号"/>
      </el-form-item>
      <el-form-item label="车牌号:" prop="license_plate_number">
        <el-input
            clearable
            v-model="form.license_plate_number"
            placeholder="请输入车牌号"/>
      </el-form-item>
      <el-form-item label="服务类型" prop="items">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-checkbox-group v-model="form.items">
          <el-checkbox v-for="(item,index) in service_type" :key="index" :label="item.serial_number" @change="selectchange">{{item.label}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>


    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save"
          v-auths="[`${$config.uniquePrefix}yunli:Selfoperateddriver:put`]">提交
      </el-button>
    </div>

  </el-dialog>
</template>

<script>
import {Addcustomers, Editcustomers} from "@/api/custom";
//引入的接口
import {
  Addclone_drivers, Detailsclone_drivers, Editclone_drivers,
  getservice_type,
} from '@/api/yunli'

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 表单验证规则
      rules: {
        realname: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          { min: 0, max: 10, message: '长度最多输入10个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur"
          }
        ],
        license_plate_number: [
          {required: true, message: '请输入车牌号', trigger: 'blur'},
          { min: 0, max: 8, message: '长度最多输入8个字符', trigger: 'blur' }
        ],
        items: [
          { type: 'array', required: true, message: '请选择服务类型', trigger: 'change' }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      // 全选
      checkAll: false,
      //服务类型
      service_type:[],

    };
  },
  // watch: {
  //   data() {
  //     if (this.data) {
  //       console.log(this.data)
  //       this.form = Object.assign({}, this.data);
  //       this.isUpdate = true;
  //     } else {
  //       this.form = {
  //         serve_type:[]
  //       };
  //       this.isUpdate = false;
  //     }
  //   }
  // },
  mounted() {
    //调用获取服务类型
    this.getservice_type();

    if (this.data) {
      console.log(this.data)
      // 获取详情
      Detailsclone_drivers(this.data.id).then(res => {
        if(res.code == 200){
          this.form = res.data;
          this.isUpdate = true;
          getservice_type().then(res => {
            // console.log(res)
            this.service_type = res.data;
            console.log(this.form.items.length)
            console.log(this.service_type.length)
            if(this.form.items.length == this.service_type.length){
              this.checkAll = true
            }else {
              this.checkAll = false;
            }
          })
        }else {
          console.log('失败')
        }
      })
    } else {
      this.form = {
        items:[]
      };
      this.isUpdate = false;
    }

  },
  methods: {
    //获取服务类型
    getservice_type(){
      getservice_type().then(res => {
        console.log(res)
        this.service_type = res.data;
      })
    },

    //点击服务类型全选
    handleCheckAllChange(){
      if(this.checkAll == true){
        this.form.items = [];
        this.service_type.forEach(item => {
          this.form.items.push(item.serial_number)
        })
      }else {
        this.form.items = [];
      }
    },
    // 服务类型选中值
    selectchange(){
      // 如果选择的数组长度等于总共的服务类型长度全选为true
      if(this.form.items.length == this.service_type.length){
        this.checkAll = true
      }else {
        this.checkAll = false;
      }
    },

    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true;
          //判断是新增还是修改
          if(this.isUpdate === false){
            Addclone_drivers(this.form).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.form = {};
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }else {
            let data = this.form;
            Editclone_drivers(data.id,data).then(res => {
              console.log(res)
              this.loading = false;
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.form = {};
                this.updateVisible(false);
                this.$emit('done');
              } else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.loading = false;
              this.$message.error(e.msg);
            })
          }
        } else {
          // 聚焦功能
          this.$firstInputFocus();
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },


  }
}
</script>

<style scoped lang="scss">
</style>
